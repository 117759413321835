import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ContactMap from '../components/images/contactMap';
import HeaderImage from '../components/images/header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PetraImage from '../components/images/petra'

// SEO og:image
import ogImage from '../images/logo/logo.png';

class IndexPage extends React.Component {
	render() {
		return (
      <Layout>
        <SEO
          title="Art Jewelry"
          description="Handcrafted using traditional techniques in brass,copper,sterling silver and others materials."
          keywords={[]}
          image = {ogImage}
        />

        <HeaderImage />

        <section className="bg-dark" id="our-story">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h3 class="text-white">Petra - MOJA PRIČA</h3>
                <p>Izrađujem nakit kako bih izrazila svoje misli i osjećaje, istraživala ili samo stvarala. Većina mojih radova pod utjecajem je ljepote mog rodnog zavičaja, mog otoka.</p>
                <p>NaOtoku znači na otoku, što za mene predstavlja mjesto mira, opuštanja i nepresušne inspiracije. Inspiracije je svugdje, priroda, forma, pokret, dodir… Koristim dosta različitih materijala kao što su mesing, bakar, srebro, reciklirana plastika, različite vrste kamenja…</p>
                <p>Moj nakit često se miješa s malim perlicama prikačenima najlonskom niti. Svaki komad nakita je unikatan ili napravljen u malim serijama.</p>
              </div>
              <div class="col-md-4">
                <PetraImage/>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white" id="contact">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <h3>KONTAKT</h3>
                <hr/>
                <p>
                  <strong>Phone:</strong> 099 560 3356<br/>
                  <strong>E-mail:</strong> p.markusovic@gmail.com
                </p>
                <hr/>
                <p>
                  naOtoku, obrt za izradu imitacije nakita i trgovinu<br/>
                  vl. Petra Markusović<br/>
                  Brune Bušića 24<br/>
                  10020 Zagreb
                </p>
                <hr/>
                <p>
                  Privredna Banka Zagreb (PBZ)<br/>
                  HR5523400091160542450
                </p>
              </div>
              <div className="col-md-7">
                <div className="card">
                  <div className="card-body">
                    <form action="https://formspree.io/p.markusovic@gmail.com" method="POST">
                      <div className="form-group">
                        <label for="formName">Ime</label>
                        <input type="text" className="form-control" id="formName" name="name"/>
                      </div>
                      <div className="form-group">
                        <label for="formEmail">E-mail <span className="text-danger">*</span></label>
                        <input type="email" className="form-control" id="formEmail" name="email" required />
                      </div>
                      <div className="form-group">
                        <label for="formTextarea">Poruka <span className="text-danger">*</span></label>
                        <textarea className="form-control" id="formTextarea" name="message" rows="6" required></textarea>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="formCheck" required />
                        <label className="form-check-label" htmlFor="formCheck">
                          Razumijem da će gore navedene informacije biti pohranjene samo u poslovne svrhe i poslane putem usluge <a href="https://formspree.io/" target="_blank" rel="noopener noreferrer">Formspree service</a>.
                        </label>
                      </div>
                      <button type="submit" className="mt-5 btn btn-primary float-right" value="Send"><FontAwesomeIcon className="mr-2" icon="paper-plane" /> Pošalji</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <a href="https://goo.gl/maps/hk9rkzCHPs2HMYXB7" target="_blank" rel="noopener noreferrer">
          <ContactMap />
        </a>
			</Layout>
		);}
}

export default IndexPage;