import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';

export default class FooterSection extends React.Component {
  render() {
    return (
      <>
      <footer className="footer">
        <section className="bg-light">
          <div className="container">
            <div className="row">
              <div className="col text-center">
                <a href="https://www.facebook.com/naotokujewelry/" aria-label="Facebook" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="m-2" icon={['fab', 'facebook']} size="4x" /></a>
                <a href="https://www.instagram.com/naotoku_jewelry/"  aria-label="Instagram" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="m-2" icon={['fab', 'instagram']} size="4x"/></a>
                <p className="mt-3">
                  NaOtoku ©2018-{new Date().getFullYear()}. Kôd licenciran pod licencom MIT-style.<br />
                  Version ORI-0.0.2-Build.20191030
                </p>
              </div>
            </div>
          </div>
        </section>
      </footer>
      </>
    );
  }
}
